<template>

  <div class="row">
    <div class="col-12 card">
      <h4>{{ report_title }}</h4>
      <router-link :to="{path: '/reports' }"><h5><span class="icon-ArrowLeft"></span> Назад к Отчётам </h5></router-link>
      <div class="row justify-content-space-between top-24" v-if="!currentProtectionReport.finish">
        <div class="col-12 align-self-end right">
          <router-link :to="{path: '/reports/' }"><button class="col-3 btn-secondary">Отменить</button></router-link>
          <button class="col-3 btn-primary" v-on:click="destroyChecklist"><span class="icon-Plus"></span>Удалить</button>
          <button class="col-3 btn-primary" v-on:click="updateChecklist"><span class="icon-Plus"></span>Сохранить</button>
          <button class="col-3 btn-primary" v-on:click="sendChecklist"><span class="icon-Check" ></span>Сдать</button>
        </div>
      </div>
      <div class="row">

        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Организация</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" v-model="currentProtectionReport.company_name" disabled="True">
            </div>
          </div>
        </div>
        <div class="col-12 top-24" v-if="currentProtectionReport.car_number">
          <div class="text-field">
            <label class="text-field__label" for="search">Автомобиль</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" v-model="currentProtectionReport.car_number" disabled="True">
            </div>
          </div>
        </div>
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Дата отчёта</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" v-model="reportDate" disabled="True">
            </div>
          </div>
        </div>
          <ItemTableView :answers = Answers :finish = currentProtectionReport.finish></ItemTableView>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import ItemTableView from "@/components/Reports/LaborProtection/Elements/protectionItemView/ItemTableView.vue";

export default {
  components: { ItemTableView },
  data() {
    return {
      checklistId: this.$route.params.id,
      report_title: '',
    }
  },
  created() {
    this.$store.state.answers = [];
    this.$store.dispatch("fetchAnswers", this.checklistId);
  },
  computed: {
    Answers() {
      return this.$store.state.answers.sort((a, b) => a.id - b.id)
    },
    currentProtectionReport() {
      return this.$store.state.checklists.find(checklist => checklist.id == this.checklistId);
    },
    reportDate() {
      return moment(String(this.currentProtectionReport.period)).format('DD.MM.YYYY')
    },
  },
  methods: {
    destroyChecklist() {
      if(confirm("Вы действительно хотите удалить этот чеклист?")) {
        axios.delete(this.$store.state.domain+'/api/protection/'+ this.checklistId +'/destroy')
        .then(() => {
          this.$router.push({ path: '/reports/'})
        })
      }
    },
    updateChecklist() {
      this.$router.push({ path: '/reports/'})
    },
    sendChecklist() {
      axios.put(this.$store.state.domain+'/api/checklist/'+this.checklistId +'/update', {
          finish: true,
        }).then(() => {
          this.$router.push({ path: '/reports/'})
      })
    }
  }

};
</script>
