<template>
    <tr>
        <td>
            {{ question }}
        </td>
        <td>
            <div class="text-field__icon" v-if="finish">
                {{ answer_result }}
            </div>
            <div class="select" v-else>
                <select id="standard-select" @change="onChange($event)" v-model="answerResult">
                    <option disabled value="">Выберите вариант</option>
                    <option value="" selected="selected"></option>
                    <option value="Да">Да</option>
                    <option value="Нет">Нет</option>
                    <option value="Не применимо">Не применимо</option>
                </select>
            </div>
        </td>
        <td>
            <div class="text-field__icon" v-if="finish">
                {{ comment }}
            </div>
            <div class="text-field__icon" v-else>
                <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите комментарий" v-model="answerComment" @change="onChange($event)">
            </div>
        </td>
        <td>
            <div class="text-field__icon" v-if="finish">
                <img v-if="answerImage != ''" :src="answerImage" width="200" />
            </div>
            <div class="text-field__icon" v-else>
                <input type="file" accept="image/*" @change="onImageChange" />
                <div id="preview" v-if="answerImage != ''">
                    <img v-if="item.imageUrl" :src="item.imageUrl" width="200" />
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import axios from 'axios'

    export default {
        props: ['id', 'question', 'answer_result', 'comment', 'image', 'finish'],
        data() {
            return {
                answerComment: this.comment,
                answerResult: this.answer_result,
                answerImage: this.image,
                _imageFile: this.imageFile,
                item:{
                    image : null,
                    imageUrl: this.image
                }
            }
        },
        methods: {
            onChange(event) {
                axios.put(this.$store.state.domain+'/api/answer/'+this.id +'/update', {
                    answer_result: this.answerResult,
                    comment: this.answerComment,
                })
            },
            onImageChange(event) {
                const file = event.target.files[0]
                this.item.image = file
                this.item.imageUrl = URL.createObjectURL(file)
                this.answerImage = this.item.image

                axios.put(this.$store.state.domain+'/api/answer/'+this.id +'/update', {
                    answer_result: this.answerResult,
                    comment: this.answerComment,
                    image: this.answerImage
                },
                {
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    }
                },
                )
            }
        }
    }
</script>