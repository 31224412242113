<template>

  <table class="col-12">
    <thead>
      <tr>
        <th class="width-25">Соответствие</th>
        <th class="width-25">Результат</th>
        <th class="width-25">Примечание</th>
        <th class="width-25">Фото</th>
      </tr>
    </thead>
    <tbody>
      <ProtectionElement 
                v-for="answer in answers" 
                :key="answer.id"
                :id = "answer.id"
                :question = "answer.question"
                :answer_result = "answer.answer_result"
                :comment = "answer.comment"
                :image = "answer.image"
                :finish = "finish"
            />
    </tbody>
  </table>
</template>

<script>
    import ProtectionElement from "@/components/Reports/LaborProtection/Elements/protectionItemView/ProtectionElement.vue";

    export default {
        components: { ProtectionElement },
        props: ['answers', 'finish'],
        // computed: {
        //     protectionList () {
        //         return this.$store.state.questions
        //     }
        // }
    }
</script>